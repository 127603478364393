import { schedule_types_ids } from "constants/schedule";

export const VIEW_RECORDING_AWS_CREDS = {
  accessKeyId: `${process.env.REACT_APP_EXLY_S3_ACCESS_KEY_ID}`,
  secretAccessKey: `${process.env.REACT_APP_EXLY_S3_SECRET_ACCESS_KEY}`,
  region: `${process.env.REACT_APP_S3_REGION}`,
};

export const VIEW_RECORDING_BUCKET = process.env.REACT_APP_EXLY_S3_BUCKET;

export const MAX_SESSIONS_DAYS_COUNT = 90;

export const NONE_OPTION_SECTION_VALUE = "none";
export const NEW_SECTION_OPTION_VALUE = "new section";

export const FIRST_ITEM_INDEX = 0;
// Onboarding flow, tooltip weights. Here either batch name or session name or
// customer name column will be rendered, so both have same weights
export const MANAGE_RECORDING_TOOLTIP_WEIGHTS = {
  recording_name: "1",
  section_name: "2",
  create_section: "3",
  batch_name: "4",
  session_name: "4",
  customer_name: "4",
  save_button: "5",
};

export const MANAGE_RECORDING_TOOLTIP_DATA = {
  recording_name:
    "Easily find recordings! All the zoom recordings that could not be added to your recordings course automatically, will be added here in this way.",
  section_name:
    "Want to add this recording in the course? Just select a section where you’d like to add this recording.",
  create_section:
    "Not sure where to add? No worries. Create a new section on the go! You can add a new section in your course directly from here itself!",
  batch_name:
    "Easily link the recording to its respective batch. The recording access will go to this selected batch.",
  session_name:
    "Easily link the recording to its respective session. The recording access will go to the customers of that selected session.",
  customer_name:
    "Easily link the recording to its respective participant. The recording access will go to this selected customer.",
  save_button:
    "Chosen a location for your recording? Click on ‘Save changes’ and your recording will be shifted to your course in just a single tap!",
};

export const empty_state_props = {
  empty_list_icon: "/assets/images/manage_recordings/empty_state_icon.svg",
  alt: "no recordings yet.",
  content_title: "No unmapped recordings.",
  children:
    "Hurray! All your recordings are already mapped and shared with your customers.",
};

export const column_item_keys = {
  batch: "batch",
  session: "session",
  customer: "customer",
};

export const column_keys = {
  recordings: {
    apiKey: "name",
    headerName: "Recordings",
  },
  startedOn: {
    apiKey: "start_datetime",
    headerName: "Started On",
  },
  endedOn: {
    apiKey: "end_datetime",
    headerName: "Ended On",
  },
  recordingId: {
    apiKey: "uuid",
    headerName: "Section in Course",
  },
};

export const manage_recordings_onboarding_keys = {
  [schedule_types_ids.one_on_one_appointment]: "manage_recording_appointment",
  [schedule_types_ids.rolling_class]: "manage_recording_rolling",
  [schedule_types_ids.group_class]: "manage_recording_group",
  [schedule_types_ids.workshop]: "manage_recording_workshop",
  [schedule_types_ids.no_schedule]: "manage_recording_qpp",
  [schedule_types_ids.webinar]: "manage_recording_webinar",
};
