import React from "react";
import { Input } from "@my-scoot/component-library-legacy";
import ExlyModal from "common/Components/ExlyModal";

import styles from "./CreateNewSectionModal.module.css";

const CreateNewSectionModal = (props) => {
  const {
    open,
    handleClose,
    newSectionText,
    setNewSectionText,
    onPrimaryBtnClick,
    validationError,
    setValidationError,
  } = props;

  const handleSecondaryBtnClick = () => {
    setNewSectionText("");
    setValidationError("");
    handleClose();
  };

  return (
    <ExlyModal
      open={open}
      onClose={handleClose}
      title="New section"
      primaryBtnText="Create"
      showSecondaryBtn
      secondaryBtnText="Cancel"
      primaryBtnFullWidth={false}
      primaryBtnProps={{
        disabled: !newSectionText?.length,
      }}
      onPrimaryBtnClick={onPrimaryBtnClick}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      modal_props={{
        classes: {
          paperWidthSm: styles.modal_style,
        },
        footerClassName: styles.modal_button_style,
      }}
    >
      <div className={styles.input_wrapper_style}>
        <Input
          label={
            <div className={styles.input_label_style}>
              Section name<span className={`text-danger`}>*</span>
            </div>
          }
          onChange={(e) => setNewSectionText(e.target?.value?.trim())}
          error={validationError}
          helperText={validationError}
          errorHelperTextClassName={styles.error_text_style}
          fullWidth
          inputClassName={styles.input_style}
          placeholder="Enter section name"
        />
      </div>
    </ExlyModal>
  );
};

export default CreateNewSectionModal;
