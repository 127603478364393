import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ReactPlayer from "react-player/lazy";
import useViewRecordingModal from "./hooks/useViewRecordingModal";
import styles from "./ViewRecordingModal.module.css";

const ViewRecordingModal = (props) => {
  const { open, data, handleClose } = props;

  const { previewUrl, getPreviewRecordingUrl } = useViewRecordingModal(data);

  React.useEffect(() => {
    getPreviewRecordingUrl();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={styles.video_wrapper}>
        <IconButton className={styles.clear_icon_style} onClick={handleClose}>
          <ClearRoundedIcon />
        </IconButton>
        {previewUrl && (
          <ReactPlayer
            controls
            url={previewUrl}
            playsinline
            playing
            muted
            width="100%"
            height="100%"
          />
        )}
      </div>
    </Dialog>
  );
};

export default ViewRecordingModal;
