import React from "react";
import { getS3ItemKey } from "utils/upload/upload.utils";
import { getS3SignedUrl } from "utils/Utils";
import {
  VIEW_RECORDING_AWS_CREDS,
  VIEW_RECORDING_BUCKET,
} from "../../../constants/ManageRecordings.constant";
import { logError } from "utils/error";

const useViewRecordingModal = (data) => {
  const [previewUrl, setPreviewUrl] = React.useState("");

  /**
   * We need a signed s3 url to render the
   * video url given by the apis.
   */
  const getPreviewRecordingUrl = async () => {
    try {
      const key = getS3ItemKey(data?.url);
      const signedUrl = await getS3SignedUrl(
        key,
        VIEW_RECORDING_BUCKET,
        VIEW_RECORDING_AWS_CREDS
      );
      setPreviewUrl(signedUrl);
    } catch (error) {
      logError({
        error,
        occuredAt:
          "src/ui/pages/schedule/ManageOffering/ManageRecordings/components/ViewRecordingModal/hooks/useViewRecordingModal.js",
        when: "Trying to convert zoom video url to a signed url",
      });
    }
  };

  return {
    previewUrl,
    getPreviewRecordingUrl,
  };
};

export default useViewRecordingModal;
