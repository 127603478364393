import React from "react";
import { Dropdown } from "@my-scoot/component-library-legacy";

import styles from "./CustomSelectColumn.module.css";

const CustomSelectColumn = (props) => {
  const {
    record,
    selectedValue = {},
    width = "150px",
    options,
    handleSelectChange,
    itemKey,
  } = props;

  const { [itemKey]: selectedItem } = selectedValue;

  const handleBatchesSeletionChange = (value) => {
    const selectedOptionValue = options.find(
      (option) => option.value === value
    );
    handleSelectChange(record, {
      record,
      [itemKey]: selectedOptionValue,
    });
  };

  return (
    <div>
      <Dropdown
        value={selectedItem?.value}
        options={options}
        width={width}
        placeholder={`Select ${itemKey}`}
        handleChange={handleBatchesSeletionChange}
        restMenuProps={{
          menu_paper_classname: styles.menu_container_style,
        }}
      />
    </div>
  );
};

export default CustomSelectColumn;
