import { matchPath } from "react-router";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { manage_recordings_api } from "./ManageRecordings.api";

const manage_recordings_resourse_apis = {
  [app_route_ids[app_route_keys.manage_recordings]]: ({ pathname }) => {
    const matchResults = matchPath(pathname, {
      path: app_route_ids[app_route_keys.manage_recordings],
    });
    return {
      api_end_point: manage_recordings_api.get_recordings,
      queries: {
        rc_listing_uid: matchResults?.params?.listing_uuid,
      },
    };
  },
};

export default manage_recordings_resourse_apis;
