import React from "react";

import ExlyModal from "common/Components/ExlyModal";
import CustomRecordSectionCard from "../CustomRecordSectionCard/CustomRecordSectionCard";
import { ExlyNote } from "common/Components/ExlyNote/ExlyNote";
import styles from "./SaveSectionChangesModal.module.css";
import useSaveSectionChangesModal from "./hooks/useSaveSectionChangesModal";

const SaveSectionChangesModal = (props) => {
  const {
    open,
    handleClose,
    listingUuid,
    sectionsData = {},
    batchesData = {},
    sessionsData = {},
    customersData = {},
    handleClearMappingStates,
  } = props;

  const {
    updatedRecordingToSections,
    handleSaveChangesClick,
    isSaveChangesLoading,
  } = useSaveSectionChangesModal({
    listingUuid,
    handleClose,
    sectionsData,
    batchesData,
    sessionsData,
    customersData,
    handleClearMappingStates,
  });

  return (
    <ExlyModal
      open={open}
      onClose={handleClose}
      title="Please confirm"
      primaryBtnText="Save changes"
      primaryBtnProps={{
        fullWidth: true,
        disabled: isSaveChangesLoading,
        loading: isSaveChangesLoading,
      }}
      onPrimaryBtnClick={handleSaveChangesClick}
      showSecondaryBtn={false}
    >
      <div>
        <div className={styles.modal_sub_text_style}>
          Make sure that you’ve connected recordings to the right sections.
          Following are the mappings that will be saved and added to the course:
        </div>
        <div className={styles.recording_section_container}>
          <div className={styles.recording_section_inner_container}>
            {Object.keys(updatedRecordingToSections).map((key) => {
              const { record, section } = updatedRecordingToSections[key];
              const batch = batchesData?.[key]?.batch;
              const session = sessionsData?.[key]?.session;
              const customer = customersData?.[key]?.customer;
              return (
                <CustomRecordSectionCard
                  key={record?.uuid}
                  recordingTitle={record?.name}
                  sectionTitle={section?.label}
                  batchTitle={batch?.label}
                  sessionTitle={session?.label}
                  customerTitle={customer?.label}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.note_container_style}>
          <ExlyNote
            label={
              <div className={styles.note_description_style}>
                <span className={styles.note_heading_style}>Please note:</span>{" "}
                Upon selecting &apos;Save Changes&apos;, the indicated
                recording(s) will be relocated from this location to your
                recordings course, where all other recordings are stored.
              </div>
            }
          />
        </div>
      </div>
    </ExlyModal>
  );
};

export default SaveSectionChangesModal;
