export const FORM_KEYS = {
  type: "type",
  audience_type: "audience_type",
  instalments_payload: "instalments_payload",
};

export const INSTALMENT_PAYLOAD_KEYS = {
  change_state: "change_state",
  instalment_uuid: "instalment_uuid",
};
