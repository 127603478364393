import React from "react";
import OrderDispatchIcon from "assets/images/order_dispatch.svg";
import ExlyModal from "common/Components/ExlyModal";

const DispatchConfirmationModal = (props) => {
  const content = (
    <div className="p-5 d-flex flex-column">
      <img className="p-3" src={OrderDispatchIcon} />
      <span>Confirm: Order is dispatched</span>
    </div>
  );

  return <ExlyModal {...props}>{content}</ExlyModal>;
};
export default DispatchConfirmationModal;
