// Third party imports
import React from "react";

// Utils and providers
import { is_empty } from "utils/validations";

// Components
import {
  WhatsAppPhoneField,
  DateTimeField,
  InternationalStatusField,
  ActionField,
} from "common/Components/TableFields/TableFields";
import MoneyField from "../../../../modules/MoneyField";

export const form_data = {
  customer_name: "Customer Name",
  start_date: "Start Date",
  customer_phone: "Phone",
  listing_name: "Content Name",
  customer_email: "Email",
  total_amount: "Price Paid",
  international_transaction: "International Transaction",
  payment_date: "Purchase Date",
  validity: "Validity",
  staff: "Staff",
};

export const getTableConfig = ({
  isPricePaidFieldHidden,
  handleCheckAnswersClick,
}) => [
  {
    field: "customer_name",
    headerName: form_data.customer_name,
    emptyField: "N/A",
    isPrimary: true,
    isDrawerTitle: true,
    avatar: true,
    sortable: false,
    width: "200px",
    fixed: "left",
    ellipsis: true,
    maxCharCount: 15,
  },
  {
    field: "staff_name",
    headerName: "Assigned to",
    valueFormatter: (record) =>
      is_empty(record.staff_name) ? "N/A" : record.staff_name,
    sortable: false,
    noWrap: true,
  },
  {
    field: "customer_email",
    headerName: form_data.customer_email,
    emptyField: "N/A",
    width: "10%",
    sortable: false,
  },
  {
    field: "customer_phone",
    headerName: form_data.customer_phone,
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="customer_phone"
        countryCodeKey="country_code"
      />
    ),
    sortable: false,
    isCollapsed: true,
  },
  {
    field: "listing_name",
    headerName: form_data.listing_name,
    emptyField: "N/A",
    sortable: false,
    noWrap: true,
    ellipsis: true,
    maxCharCount: 28,
  },
  {
    field: "total_amount",
    headerName: form_data.total_amount,
    valueFormatter: (record) => (
      <MoneyField
        record={record}
        source="total_amount"
        showGstHelper={true}
        creator_sales={false}
        noRightMargin={true}
      />
    ),
    hidden: isPricePaidFieldHidden,
    hiddenDesktopColumn: isPricePaidFieldHidden,
    sortable: false,
  },
  {
    field: "is_international",
    headerName: form_data.international_transaction,
    valueFormatter: (record) => (
      <InternationalStatusField record={record} source="is_international" />
    ),
    isCollapsed: true,
    sortable: false,
  },
  {
    field: "payment_date",
    headerName: form_data.payment_date,
    valueFormatter: (record) => (
      <DateTimeField record={record} source="payment_date" />
    ),
    isCollapsed: true,
    noWrap: true,
    sortable: false,
  },
  {
    field: "expiry",
    headerName: form_data.validity,
    valueFormatter: (record) => (
      <DateTimeField record={record} source="expiry" />
    ),
    isCollapsed: true,
    noWrap: true,
    sortable: false,
  },
  {
    field: "answer_count",
    headerName: "Check Answers",
    valueFormatter: (record) => {
      if (!record?.answer_count) return "N/A";
      return (
        <ActionField
          record={record}
          label="View Answers"
          onClick={handleCheckAnswersClick}
        />
      );
    },
    noWrap: true,
    fixed: "right",
    isCollapsed: true,
    sortable: false,
  },
];

export const getFilterConfig = ({ staffMembers, showStaffFilter }) => [
  {
    source: "guest_name",
    placeholder: form_data.customer_name,
    type: "input",
    disableContains: true,
  },
  {
    source: "booked_user__email",
    placeholder: form_data.customer_email,
    type: "input",
  },
  {
    source: "listing__title",
    placeholder: form_data.listing_name,
    type: "input",
  },
  {
    source: "booked_user__phone_number",
    placeholder: form_data.customer_phone,
    type: "input",
  },
  {
    source: "org_uuid",
    placeholder: form_data.staff,
    type: "dropdown",
    options: staffMembers,
    disableContains: true,
    hidden: !showStaffFilter,
    disableContains: true,
  },
];

export const tableChipsFormatter =
  ({ staffMembers }) =>
  (filter, value) => {
    switch (filter) {
      case "guest_name":
        return `Name: ${value}`;
      case "booked_user__email__icontains":
        return `Email: ${value}`;
      case "booked_user__phone_number__icontains":
        return `Phone: ${value}`;
      case "listing__title__icontains":
        return `Content Name: ${value}`;
      case "org_uuid":
        const staff = staffMembers?.find(
          (staffMember) => staffMember.id === value
        );
        return `Staff: ${staff?.name}`;
      default:
        return undefined;
    }
  };
