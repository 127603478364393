// Third party imports
import {
  Button,
  creatorToolTheme,
  ThemeProvider,
} from "@my-scoot/component-library-legacy";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { useHistory } from "react-router";

// Styles and icons
import EmptyListIcon from "assets/vectors/infoModal/Custom_Payment.svg";

// Utils and providers
import dataProvider from "../../../../data/dataProvider";
import { getUserTimezone } from "../../../../utils/AuthUtil";
import { is_empty } from "../../../../utils/validations";

// Components
import DvrIcon from "@material-ui/icons/Dvr";
import { PAYMENT_LINK_APIS } from "features/PaymentLinks/constants/PaymentLinks.apis";
import CreatePaymentLinkModal from "features/PaymentLinks/modules/CreatePaymentLinkModal/CreatePaymentLinkModal";
import Actions from "features/PaymentLinks/modules/ExistingPaymentLinks/components/Actions/Actions";
import LinkField from "features/PaymentLinks/modules/ExistingPaymentLinks/components/LinkField/LinkField";
import StatusField from "features/PaymentLinks/modules/ExistingPaymentLinks/components/StatusField/StatusField";
import {
  EXISTING_PAYMENT_LINKS_COLUMN_CONFIG,
  EXISTING_PAYMENT_LINKS_FILTER_CONFIG,
  EXISTING_PAYMENT_LINKS_IMAGE_ASSETS,
} from "features/PaymentLinks/modules/ExistingPaymentLinks/constants/ExistingPaymentLinks.constants";
import { existingPaymentLinksFilterChipsFormatter } from "features/PaymentLinks/modules/ExistingPaymentLinks/utils/ExistingPaymentLinks.utils";
import {
  PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS,
  PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS,
} from "features/PaymentLinks/modules/PaymentLinksTransactions/constants/PaymentLinksTransactions.constants";
import { useNotifications, useToggleState } from "utils/hooks";
import ExlyTable from "../../../../common/Components/ExlyTable";
import ExpireModal from "./components/ExpireModal";
import styles from "./index.module.css";
import { PAYMENT_LINKS_URL_PATHS } from "features/PaymentLinks/constants/PaymentLinks.constants";

// TODO: update this when new designs are ready
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No custom payment links found!</div>
    </div>
  );
};

const emptyStateProps = {
  page_title: "Custom payment links",
  content_title: "No payment links yet",
  empty_list_icon: EmptyListIcon,
  alt: "no payment links yet",
  primary_cta: {
    children: "Create payment links",
    onClick: () =>
      (window.location.href = `${window.location.origin}/#${PAYMENT_LINKS_URL_PATHS.CREATE_LINK}`),
  },
  secondary_cta: { children: "View sample data" },
  preview_image: EXISTING_PAYMENT_LINKS_IMAGE_ASSETS.empty_state,
  children: "As soon as we have new payment, it will show up here.",
};

const DynamicLinkList = (props) => {
  const { pushNotification } = useNotifications();
  const refetch = useRefresh();
  const history = useHistory();
  const [showExpire, setShowExpire] = React.useState(false);
  const [isExpiringLink, startLinkExpire, stopLinkExpire] =
    useToggleState(false);
  const [selectedRecordData, setSelectedRecordData] = React.useState(null);
  const [editPaymentLinkModal, setEditPaymentLinkModal] = useState();

  const handleExpireAction = (record) => {
    setSelectedRecordData(record);
    setShowExpire(true);
  };

  const handleExpire = async (item) => {
    try {
      if (is_empty(item.dynamic_link_id)) return;
      startLinkExpire();
      const status = await dataProvider.custom_request(
        PAYMENT_LINK_APIS.update_payment_link,
        "POST",
        {
          dynamic_link_id: item.dynamic_link_id,
          updated_values: {
            expiry_date: moment().tz(getUserTimezone()).toISOString(),
          },
        }
      );
      pushNotification(status.message, {
        variant: "outlined",
        color: "primary",
      });
      if (status.status === 200) {
        setShowExpire(false);
        refetch();
      }
    } catch (err) {
      console.log("FAQ handleDelete", err);
      pushNotification("Some error occured", {
        variant: "outlined",
        color: "coral_red",
      });
    } finally {
      stopLinkExpire();
    }
  };

  const handleEditPaymentLink = (record) => {
    const handleClose = () => {
      setEditPaymentLinkModal();
    };

    setEditPaymentLinkModal(
      <CreatePaymentLinkModal record={record} open onClose={handleClose} />
    );
  };

  const handleRedirectToTransactions = () => {
    history.push(
      `/${
        PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS[
          PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS.PAYMENT_LINKS_TRANSACTIONS
        ]
      }`
    );
  };

  return (
    <>
      <ExlyTable
        ra_props={props}
        tableTitle="Existing payment links"
        filteredEmptyState={<FilteredEmptyState />}
        columnConfig={EXISTING_PAYMENT_LINKS_COLUMN_CONFIG}
        tableFilters={EXISTING_PAYMENT_LINKS_FILTER_CONFIG}
        tableChipsFormatter={existingPaymentLinksFilterChipsFormatter}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "120px",
          layoutDesktopMargin: "20px 24px",
          noMobileBoxShadow: true,
          showBackCta: true,
          secondaryComponent: (
            <div className={styles.transactionsCtaContainer}>
              <Button
                startIcon={<DvrIcon />}
                onClick={handleRedirectToTransactions}
                fullWidth
              >
                See transactions
              </Button>
            </div>
          ),
        }}
        primaryKey="id"
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        renderSecondaryAction={(record) => <LinkField record={record} />}
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <Actions
              record={record}
              onExpire={handleExpireAction}
              onEdit={handleEditPaymentLink}
            />
          ),
        }}
        renderPrimaryColumnRightNode={(record) => (
          <StatusField record={record} />
        )}
        desktopCustomPrimaryAction={{
          renderCustomAction: (record) => (
            <Actions
              record={record}
              onExpire={handleExpireAction}
              onEdit={handleEditPaymentLink}
            />
          ),
        }}
        primaryColumnProps={{
          align: "center",
          sticky: true,
          width: "90px",
        }}
        noExportButton
        hasMobileFooter
        footerProps={{
          primarybtn: "See transactions",
          primaryClick: handleRedirectToTransactions,
          hidePrimarybtn: false,
          primaryBtnProps: { startIcon: <DvrIcon /> },
        }}
        {...emptyStateProps}
      />

      <ThemeProvider theme={creatorToolTheme}>
        <ExpireModal
          open={showExpire}
          onClose={() => setShowExpire(false)}
          onPrimaryBtnClick={() => handleExpire(selectedRecordData)}
          onSecondaryBtnClick={() => setShowExpire(false)}
          isExpiringLink={isExpiringLink}
        />

        {editPaymentLinkModal}
      </ThemeProvider>
    </>
  );
};

export default DynamicLinkList;
