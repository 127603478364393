import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mode: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    lineHeight: "16px",
  },
  mode_icon: {
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    background: ({ isOnline }) =>
      isOnline
        ? theme?.palette?.fine_pine?.shade_600
        : theme?.palette?.secondary?.shade_800,
  },
  status_chip: {
    padding: "0 8px",
    height: "22px",
  },
  status_chip_label: {
    color: `${theme?.palette?.basic?.white} !important`,
  },
  status_ribbon: {
    color: theme?.palette?.basic?.white,
    borderRadius: "2px 2px 2px 0px",
  },
  status__1: {
    // Live, Active
    background: `${theme?.palette?.fine_pine?.main} !important`,
    "&:hover": {
      background: `${theme?.palette?.fine_pine?.main} !important`,
    },
  },
  status__3: {
    // Expired, cancelled
    background: `${theme?.palette?.basic?.danger_red} !important`,
    "&:hover": {
      background: `${theme?.palette?.basic?.danger_red} !important`,
    },
  },
  status__5: {
    // Deleted
    background: `${theme?.palette?.coral_red?.shade_400} !important`,
    "&:hover": {
      background: `${theme?.palette?.coral_red?.shade_400} !important`,
    },
  },
  switchRootWrapperClassName: {
    opacity: 0.5,
    cursor: "not-allowed",
    "&:hover": {
      borderColor: "transparent",
    },
  },
  switchClassName: {
    pointerEvents: "none",
  },
  status: {
    display: "flex",
    gap: "6px",
    height: "16px",
  },
  status__Pending: {
    color: "#FFAA00",
  },
  status__Dispatched: {
    color: "#02b77a",
  },
  mobile_secondary_action: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  footer__Pending: {
    background: "#FFF7E5 !important",
  },
  footer__Dispatched: {
    background: "#E5FFF7 !important",
  },
  info_field_wrapper: {
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
    "& svg": {
      width: "19.21px",
    },
  },
  listingLink: {
    color: "#0052CC",
    textDecoration: "underline",
  },
  status__pine_active: {
    // Live, Active
    background: `${theme?.palette?.fine_pine?.main} !important`,
    "&:hover": {
      background: `${theme?.palette?.fine_pine?.main} !important`,
    },
  },
  status__danger: {
    // Expired, cancelled
    background: `${theme?.palette?.basic?.danger_red} !important`,
    "&:hover": {
      background: `${theme?.palette?.basic?.danger_red} !important`,
    },
  },
}));
