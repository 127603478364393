import {
  FIRST_ITEM_INDEX,
  MANAGE_RECORDING_TOOLTIP_WEIGHTS,
  NONE_OPTION_SECTION_VALUE,
} from "../constants/ManageRecordings.constant";
import styles from "../ManageRecordings.module.css";

/**
 * Gien the object recordToSections, this function will remove all the items where
 * user has selected "-" option and return the new updated object
 * @param {object} recordToSections Object with recording uuid mapped to an object
 *                 containing record and section data
 * @returns newRecordToSection: an object with all those keys removed where the
 *          user selected "-" from section dropdown
 */
export const getUpdatedRecordingToSections = (recordToSections) => {
  const newRecordToSection = {};
  Object.keys(recordToSections).forEach((key) => {
    const { section } = recordToSections[key];
    if (section?.value !== NONE_OPTION_SECTION_VALUE) {
      newRecordToSection[key] = recordToSections[key];
    }
  });
  return newRecordToSection;
};

/**
 *
 * @param {object} record Single Table record data.
 * @param { string } currentActive String containing the current weight
 * of the active onboarding tooltip shown
 * @returns
 */
export const getSingleRecordClassName = (record, currentActive) => {
  if (
    record?.id === FIRST_ITEM_INDEX &&
    [
      MANAGE_RECORDING_TOOLTIP_WEIGHTS.recording_name,
      MANAGE_RECORDING_TOOLTIP_WEIGHTS.section_name,
      MANAGE_RECORDING_TOOLTIP_WEIGHTS.create_section,
      MANAGE_RECORDING_TOOLTIP_WEIGHTS.batch_name,
      MANAGE_RECORDING_TOOLTIP_WEIGHTS.customer_name,
    ].includes(currentActive)
  ) {
    return styles.tooltip_wrapper;
  }
};
