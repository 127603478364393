// Third party imports
import React, { useState, useEffect } from "react";
import { downloadCSV } from "react-admin";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "@my-scoot/component-library-legacy";
import {
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";

import EmptyListIcon from "assets/vectors/infoModal/Content_Purchase.svg";

// Utils and providers
import {
  RESOURCE_KEYS,
  orgPermissions,
} from "../../../../../utils/OrgPermissions";
import dataProvider from "../../../../../data/dataProvider";
import api from "../../../../../data/APIs";
import {
  getFilterConfig,
  getTableConfig,
  tableChipsFormatter,
  form_data,
} from "./ContentListTableConfig";
import { useToggleState } from "utils/hooks";
import { is_empty } from "utils/validations";
import { getAnswerText, handleDateFormat } from "utils/Utils";
import jsonExport from "jsonexport/dist";
import { getUserCurrencySymbol } from "../../../../../utils/AuthUtil";
import constants from "../../../../../constants/constants";

// Components
import ExlyTable from "common/Components/ExlyTable";
import {
  BookingQuestions,
  DetailsDrawerFooter,
  WhatsAppButton,
} from "webpage-leads/components/CustomColumns";
import { ActionField } from "../../../../../common/Components/TableFields/TableFields";
import AddCustomerModal from "features/Crm/modules/AddCustomerModal/components/AddCustomerModal";

// TODO: Empty State UI Pending
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No contents found!</div>
    </div>
  );
};

// Main Component
const ContentList = (props) => {
  const [is_add_modal_active, show_add_modal, hide_add_modal] =
    useToggleState(false);
  const [is_answer_modal_active, show_answer_modal, hide_answer_modal] =
    useToggleState(false);
  const [answer_modal_data, set_answer_modal_data] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);

  const isDesktop = useMediaQuery("(min-width: 767px)");

  const handleCheckAnswersClick = ({ record = {} }) => {
    set_answer_modal_data(record);
    show_answer_modal();
  };

  const getOrgMembers = async () => {
    try {
      const { data } = await dataProvider.custom_request(
        api.get_assignee_list,
        "GET",
        { page: "all" }
      );

      if (data && data.assignment_members) {
        let members = [];
        data.assignment_members.map((member) => {
          members.push({
            id: member.org_uuid,
            name: member.member_name,
            label: member.member_name,
            value: member.org_uuid,
          });
        });
        setStaffMembers(members);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrgMembers();
  }, []);

  const exporter = (response) => {
    const dataForExport = response.map((data) => {
      let return_data = {};
      for (let key in form_data) {
        if (!is_empty(data[key])) return_data[form_data[key]] = data[key];
      }
      return_data[form_data.payment_date] = handleDateFormat(data.payment_date);
      return_data[form_data.international_transaction] = data[
        "is_international"
      ]
        ? "Yes"
        : "No";
      return_data[form_data.total_amount] = `${getUserCurrencySymbol()} ${(
        data.total_amount * data.exchange_rate
      )?.toFixed(2)}`;
      return_data[form_data.customer_phone] = !is_empty(data.customer_phone)
        ? `${data.country_code} ${data.customer_phone}`
        : "N/A";
      if (data?.answer_count > 0 && !is_empty(data.answer_list)) {
        for (let item of data.answer_list) {
          if (item.question_text in return_data) continue;
          return_data[item.question_text] = getAnswerText(item);
        }
      }
      return return_data;
    });
    jsonExport(dataForExport, (err, csv) => {
      downloadCSV(csv, "Content Purchases"); // download as 'posts.csv` file
    });
  };

  const emptyStateProps = {
    page_title: "Content Purchases",
    content_title: "No Contents yet",
    empty_list_icon: EmptyListIcon,
    alt: "no content",
    primary_cta: {
      children: "Add a New Purchase",
      onClick: () => {
        window.sessionStorage.setItem("fromRoute", "Content Purchases");
        window.location.href = `${window.location.origin}/#/CreateScheduleType`;
      },
    },
    secondary_cta: { children: "View sample data" },
    preview_image: constants.preview_content_purchases,
    children: "As soon as we have new content, it will show up here.",
  };

  const isPricePaidFieldHidden = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MY_BOOKINGS,
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.PRODUCT_ORDER_PURCHASE,
    RESOURCE_KEYS.FIELDS.MY_BOOKINGS.CONTENT_PURCHASE.PRICE_PAID
  );

  const showStaffFilter =
    orgPermissions.isOwner() ||
    orgPermissions.isMainUser() ||
    orgPermissions.isManager();

  const footerProps = {
    primarybtn: "Add a New Purchase",
    primaryClick: show_add_modal,
    hidePrimarybtn: !orgPermissions.canCreate(),
  };

  const secondaryComponent = (
    <Button color="primary" size="medium" onClick={show_add_modal}>
      Add a New Purchase
    </Button>
  );

  return (
    <>
      <ExlyTable
        ra_props={{
          ...props,
          exporter: orgPermissions.canExport() ? exporter : false,
        }}
        noExportButton={!orgPermissions.canExport()}
        tableTitle="Content Purchases"
        layoutProps={{
          secondaryComponent:
            isDesktop && orgPermissions.canCreate() && secondaryComponent,
          paddingDesktop: "0",
          paddingBottom: "120px",
          layoutDesktopMargin: "12px 0 0 19px",
          noMobileBoxShadow: true,
        }}
        columnConfig={getTableConfig({
          isPricePaidFieldHidden,
          handleCheckAnswersClick,
        })}
        tableFilters={getFilterConfig({ showStaffFilter, staffMembers })}
        tableChipsFormatter={tableChipsFormatter({ staffMembers })}
        hasMobileFooter={true}
        footerProps={footerProps}
        renderPrimaryColumnRightNode={(record) => (
          <WhatsAppButton
            record={record}
            source="customer_phone"
            countryCodeKey="country_code"
          />
        )}
        filteredEmptyState={<FilteredEmptyState />}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        customPrimaryAction={{
          label: "See More",
          onClick: (
            row,
            rows,
            setDrawerConfig,
            setActionsDrawerConfig,
            rowIndex
          ) =>
            setDrawerConfig({
              drawerActionType: "view",
              open: true,
              row,
              rowIndex,
            }),
        }}
        renderDrawerFooter={(record) => (
          <DetailsDrawerFooter
            record={record}
            source="customer_phone"
            countryCodeKey="country_code"
          />
        )}
        renderSecondaryAction={(record) => {
          if (!record?.answer_count) return "N/A";
          return (
            <ActionField
              record={record}
              label="View Answers"
              onClick={handleCheckAnswersClick}
            />
          );
        }}
        primaryKey="id"
        {...emptyStateProps}
      />

      <ThemeProvider theme={creatorToolTheme}>
        {is_answer_modal_active && (
          <BookingQuestions
            data={answer_modal_data}
            isOpen={is_answer_modal_active}
            hideModal={hide_answer_modal}
            title="Booking Q&A"
          />
        )}
        {is_add_modal_active && (
          <AddCustomerModal
            open={is_add_modal_active}
            onClose={hide_add_modal}
            listing_type={5}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default ContentList;
