import React from "react";
import { format } from "date-fns";
import { ActionField } from "ui/pages/CourseAnalytics/components/HelperComponents/HelperComponents";
import CourseSectionColumn from "../components/CourseSectionColumn/CourseSectionColumn";
import CustomSelectColumn from "../components/CustomSelectColumn/CustomSelectColumn";
import { InteractiveTooltipWrapper } from "schedule-v2/components";
import { DATE_YEAR_MONTH_DAY_TIME_FORMAT } from "constants/date-fns/dateTime.constant";
import {
  FIRST_ITEM_INDEX,
  MANAGE_RECORDING_TOOLTIP_DATA,
  MANAGE_RECORDING_TOOLTIP_WEIGHTS,
  column_item_keys,
  column_keys,
} from "../constants/ManageRecordings.constant";
import styles from "../ManageRecordings.module.css";

export const getColumnConfig = (
  handleViewRecordingActionClick,
  recordToSelectedSection,
  handleSelectedSectionMappingChange,
  courseSectionOptions,
  setCourseSectionOptions,
  batchesProps = {},
  sessionsProps = {},
  customersProps = {},
  currentActive,
  isDesktop
) => {
  const columns = [
    {
      field: column_keys.recordings.apiKey,
      headerName: column_keys.recordings.headerName,
      fixed: "left",
      noWrap: true,
      isPrimary: true,
      ...(isDesktop
        ? {
            valueFormatter: (record) => (
              <>
                <div>{record?.[column_keys.recordings.apiKey]}</div>
                {record.id === FIRST_ITEM_INDEX && (
                  <InteractiveTooltipWrapper.Tooltip
                    weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.recording_name}
                    bottom={true}
                    content={MANAGE_RECORDING_TOOLTIP_DATA.recording_name}
                    className={styles.tooltip_header}
                  />
                )}
              </>
            ),
          }
        : {
            renderColumn: (record) => (
              <>
                <div className={styles.mobile_icon_text_container}>
                  <img
                    src="/assets/images/manage_recordings/recording_icon.svg"
                    alt="recording"
                  />
                  {record?.[column_keys.recordings.apiKey]}
                </div>
                {record.id === FIRST_ITEM_INDEX && (
                  <InteractiveTooltipWrapper.Tooltip
                    weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.recording_name}
                    bottom={true}
                    content={MANAGE_RECORDING_TOOLTIP_DATA.recording_name}
                    className={styles.tooltip_header}
                  />
                )}
              </>
            ),
          }),
    },
    {
      field: column_keys.startedOn.apiKey,
      headerName: column_keys.startedOn.headerName,
      valueFormatter: (record) =>
        format(
          new Date(record?.[column_keys.startedOn.apiKey]),
          DATE_YEAR_MONTH_DAY_TIME_FORMAT
        ),
    },
    {
      field: column_keys.endedOn.apiKey,
      headerName: column_keys.endedOn.headerName,
      valueFormatter: (record) =>
        format(
          new Date(record?.[column_keys.endedOn.apiKey]),
          DATE_YEAR_MONTH_DAY_TIME_FORMAT
        ),
    },
    {
      field: "",
      headerName: column_keys.recordingId.headerName,
      valueFormatter: (record) => {
        return (
          <div>
            <CourseSectionColumn
              record={record}
              selectedValue={
                recordToSelectedSection?.[
                  record?.[column_keys.recordingId.apiKey]
                ]
              }
              handleSectionChange={handleSelectedSectionMappingChange}
              courseSectionOptions={courseSectionOptions}
              setCourseSectionOptions={setCourseSectionOptions}
              currentActive={currentActive}
            />
            {record.id === FIRST_ITEM_INDEX && (
              <InteractiveTooltipWrapper.Tooltip
                weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.section_name}
                bottom={true}
                content={MANAGE_RECORDING_TOOLTIP_DATA.section_name}
                className={styles.tooltip}
              />
            )}
          </div>
        );
      },
    },
    ...(batchesProps?.showBatchesColumn
      ? [
          {
            field: "",
            headerName: "Batch",
            valueFormatter: (record) => (
              <>
                <CustomSelectColumn
                  record={record}
                  selectedValue={
                    batchesProps.recordToSelectedBatch?.[
                      record?.[column_keys.recordingId.apiKey]
                    ]
                  }
                  options={batchesProps.options}
                  handleSelectChange={batchesProps.handleBatchChange}
                  itemKey={column_item_keys.batch}
                />
                {record.id === FIRST_ITEM_INDEX && (
                  <InteractiveTooltipWrapper.Tooltip
                    weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.batch_name}
                    bottom={true}
                    content={MANAGE_RECORDING_TOOLTIP_DATA.batch_name}
                    className={styles.tooltip}
                  />
                )}
              </>
            ),
          },
        ]
      : []),
    ...(sessionsProps.showSessionsColumn
      ? [
          {
            field: "",
            headerName: "Attendees of Session",
            valueFormatter: (record) => (
              <>
                <CustomSelectColumn
                  record={record}
                  selectedValue={
                    sessionsProps.recordToSelectedSession?.[
                      record?.[column_keys.recordingId.apiKey]
                    ]
                  }
                  options={sessionsProps.options}
                  handleSelectChange={sessionsProps.handleSessionChange}
                  width={isDesktop ? "150px" : "130px"}
                  itemKey={column_item_keys.session}
                />
                {record.id === FIRST_ITEM_INDEX && (
                  <InteractiveTooltipWrapper.Tooltip
                    weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.session_name}
                    bottom={true}
                    content={MANAGE_RECORDING_TOOLTIP_DATA.session_name}
                    className={styles.tooltip}
                  />
                )}
              </>
            ),
          },
        ]
      : []),
    ...(customersProps.showCustomersColumn
      ? [
          {
            field: "",
            headerName: "Customer name",
            valueFormatter: (record) => (
              <>
                <CustomSelectColumn
                  record={record}
                  selectedValue={
                    customersProps.recordToSelectedCustomer?.[
                      record?.[column_keys.recordingId.apiKey]
                    ]
                  }
                  options={customersProps.options}
                  handleSelectChange={customersProps.handleCustomerChange}
                  itemKey={column_item_keys.customer}
                />
                {record.id === FIRST_ITEM_INDEX && (
                  <InteractiveTooltipWrapper.Tooltip
                    weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.customer_name}
                    bottom={true}
                    content={MANAGE_RECORDING_TOOLTIP_DATA.customer_name}
                    className={styles.tooltip}
                  />
                )}
              </>
            ),
          },
        ]
      : []),
    {
      field: "",
      headerName: "Actions",
      fixed: "right",
      valueFormatter: (record) => (
        <ActionField
          record={record}
          label="View recording"
          onClick={(record) => {
            handleViewRecordingActionClick(record);
          }}
        />
      ),
      hidden: true,
    },
  ];

  return columns;
};
