import React, { useCallback } from "react";
import { Typography } from "@material-ui/core";
import classnames from "classnames";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import useStyles from "./TableFields.styles";

export const EmailField = ({ record }) => {
  const classes = useStyles();

  const sendEmail = useCallback(
    (email) => {
      let recipient_list = [
        {
          key: "extra",
          emails: [{ name: email, email: email }],
          display_name: email,
        },
      ];
      sessionStorage.setItem("recipient_list", JSON.stringify(recipient_list));
      window.location.href = `${window.location.origin}/#/ComposeEmail`;
    },
    [record]
  );

  return (
    <Typography
      className={classes.emailLink}
      component="span"
      onClick={() => sendEmail(record.email)}
    >
      {record.email}
    </Typography>
  );
};

export const LinkField = ({ record, source, linkText, onClick, className }) => {
  const classes = useStyles();

  return (
    <Typography
      onClick={() => onClick(record, source)}
      className={classnames(classes.linkField, className)}
      component="span"
    >
      {linkText}
    </Typography>
  );
};

export const InfoField = ({ record = {}, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.info_field_wrapper} onClick={() => onClick(record)}>
      <InfoRoundedIcon />
      View Details
    </div>
  );
};
