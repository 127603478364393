import React from "react";
import Inbox from "@material-ui/icons/Inbox";

const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <Inbox style={{ fontSize: 60 }} />
      <div>No bookings found!</div>
    </div>
  );
};

export default FilteredEmptyState;
