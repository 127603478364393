import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import { InteractiveTooltipWrapper } from "schedule-v2/components";
import {
  MANAGE_RECORDING_TOOLTIP_DATA,
  MANAGE_RECORDING_TOOLTIP_WEIGHTS,
} from "../../constants/ManageRecordings.constant";

import styles from "./SaveChangesButton.module.css";

const SaveChangesButton = (props) => {
  const { currentActive, isDesktop, handleSaveChnagesClick } = props;

  return (
    <div
      className={
        currentActive === MANAGE_RECORDING_TOOLTIP_WEIGHTS.save_button &&
        styles.tooltip_wrapper
      }
    >
      <Button fullWidth={!isDesktop} onClick={handleSaveChnagesClick}>
        Save Changes
      </Button>
      <InteractiveTooltipWrapper.Tooltip
        weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.save_button}
        bottom={true}
        content={MANAGE_RECORDING_TOOLTIP_DATA.save_button}
        className={styles.tooltip}
      />
    </div>
  );
};

export default SaveChangesButton;
