import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  footer: {
    paddingRight: "12px",
    justifyContent: "space-between",
  },
  learnMoreButton: {
    textDecoration: "underline",
  },
});
export default useStyles;
