// Third party imports
import React from "react";
import classnames from "classnames";
import { Chip } from "@my-scoot/component-library-legacy";

// Styles and Icons
import useStyles from "./TableFields.styles";

// Utils and providers
import constants from "constants/constants";
import { is_empty } from "../../../../../../utils/validations";
import { ReactComponent as DispatchVehicleIcon } from "../../../../../../assets/vectors/dispatch_vehicle_success.svg";
import { ReactComponent as PendingVehicleIcon } from "../../../../../../assets/vectors/dispatch_vehicle_yellow.svg";
import { Switch } from "@my-scoot/component-library-legacy";
import { ListingTypeChip } from "common/Components/ListingTypeChip/ListingTypeChip";
import {
  BOOKINGS_STATUS_CODES,
  BOOKINGS_STATUS_CODES_ENUM,
} from "constants/schedule";
import {
  isListingMerchandise,
  isListingWhatsappCommunity,
} from "features/Listings/utils/Listings.utils";
import LinkField from "features/Crm/modules/Bookings/components/LinkField/LinkField";
import styles from "./TableFields.module.css";

export const MeetingAddress = ({ record = {}, isInfoModal = false }) => {
  const isDesktop = window.screen.width > constants.mobile_width;
  const isWhatsappCommunity = isListingWhatsappCommunity({
    type: record.listing_type,
  });

  if (
    record.customer_address &&
    !isListingMerchandise({ type: record.listing_type })
  )
    return `${record.customer_address}`;
  if (
    record.booking_address &&
    !isListingMerchandise({ type: record.listing_type })
  )
    return `${record.booking_address}`;
  if (is_empty(record.venue_details)) return "-";

  const handleZoomExlyClick = () => {
    window.open(record?.venue_details?.joining_link, "_blank");
  };

  //@dev todo did not revamp this as this logical is to be moved to BE
  return (
    <>
      {record?.listing_mode?.toLowerCase() === "online" ||
      record?.listing_mode?.toLowerCase() === "online" ? (
        <>
          {!is_empty(record.venue_details?.whatsapp_link) &&
            !isWhatsappCommunity && (
              <>
                <span>WhatsApp:</span>{" "}
                <span>{record.venue_details.whatsapp_link}</span>
              </>
            )}

          {!is_empty(record.venue_details?.contact_number) && (
            <>
              <span>Phone:</span>{" "}
              <span>{record.venue_details.contact_number}</span>
            </>
          )}

          {isWhatsappCommunity ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: isInfoModal
                  ? "flex-end"
                  : isDesktop
                  ? "flex-start"
                  : "flex-end",
              }}
            >
              <LinkField
                sortable={false}
                source="whatsapp_link"
                record={record.venue_details}
              />
            </div>
          ) : null}

          {!is_empty(record?.venue_details?.exly_zoom_link_customer) &&
            !is_empty(record?.venue_details?.joining_link) && (
              <div
                className={styles.launchMeetingStyle}
                onClick={handleZoomExlyClick}
              >
                Launch Meeting
              </div>
            )}

          {(!is_empty(record.venue_details?.joining_link) ||
            !is_empty(record.venue_details?.joining_password)) &&
            is_empty(record?.venue_details?.exly_zoom_link_customer) && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: isInfoModal
                      ? "flex-end"
                      : isDesktop
                      ? "flex-start"
                      : "flex-end",
                  }}
                  className="align-items-center"
                >
                  <LinkField
                    sortable={false}
                    source="joining_link"
                    record={record.venue_details}
                  />
                </div>
                {record.venue_details?.joining_password ? (
                  <>
                    <span>Password:</span>{" "}
                    <span>{record.venue_details.joining_password}</span>
                  </>
                ) : null}
              </>
            )}

          {is_empty(record.venue_details?.joining_link) &&
            is_empty(record.venue_details?.joining_password) &&
            is_empty(record.venue_details?.contact_number) &&
            is_empty(record.booking_address) &&
            is_empty(record.venue_details?.whatsapp_link) && <span>-</span>}
        </>
      ) : record?.mode?.toLowerCase() === "offline" ||
        record?.listing_mode?.toLowerCase() === "offline" ? (
        record.address_type === 3 ? (
          <span>At {"customer's"} address</span>
        ) : (
          <div>{record.venue_details.address}</div>
        )
      ) : (
        "-"
      )}
    </>
  );
};

export const ListingTypeField = ({ record = {}, source }) => {
  const filteredType = constants.schedule_type.filter(
    (value) => value.id === record[source]
  );
  return filteredType.length ? filteredType[0].name : "-";
};

export const OfferingNameField = ({ record = {}, source, onClick }) => {
  const classes = useStyles();
  return (
    <>
      {record[source] ? (
        <div onClick={(e) => onClick(e, record)}>
          <a
            href={record[source]}
            className={classes.listingLink}
            onClick={(e) => onClick(e, record)}
          >
            {record[source]}
          </a>
        </div>
      ) : (
        <p>-</p>
      )}
    </>
  );
};

export const ModeField = ({ record = {}, source, showModeLabel }) => {
  const isOnline = record[source] === "Online";
  const isDesktop = window.screen.width > constants.mobile_width;
  const classes = useStyles({ isOnline });
  if (is_empty(record[source])) return "-";

  return (
    <div className={classes.mode}>
      <div className={classes.mode_icon} />
      <span>{isOnline ? "Online" : "Offline"}</span>
      {!isDesktop && showModeLabel && <span>Mode</span>}
    </div>
  );
};

export const ListingTypeFieldFormatted = ({ record = {}, source }) => {
  const listingTypeId = record[source];
  const listingTypeObj = constants.schedule_type[listingTypeId];
  const listingType = listingTypeObj?.name;

  if (!listingType) return "-";

  return <ListingTypeChip listingTypeId={listingTypeId} />;
};

export const StatusField = ({ record = {} }) => {
  const statusObj = constants.listing_status[record.listing_status];
  const status = statusObj?.name;
  const classes = useStyles();

  if (!status) return "-";

  return (
    <Chip
      chipClassName={classnames(
        classes.status_chip,
        classes[`status__${statusObj.id}`]
      )}
      labelClassName={classes.status_chip_label}
      label={status}
    />
  );
};
export const SwitchField = ({ record = {}, source, onChange }) => {
  const classes = useStyles();

  return (
    <Switch
      rootWrapperClassName={
        record[source] !== 1 && classes.switchRootWrapperClassName
      }
      switchClassName={record[source] !== 1 && classes.switchClassName}
      checked={parseInt(record[source]) === 2}
      onChange={() => onChange(record)}
      color="success"
      size="large"
    />
  );
};

export const DispatchStatus = ({ record = {}, source }) => {
  const classes = useStyles();
  const dispatchStatus = record[source];
  const status = constants.product_dispatch_status[record[source]]?.name;
  return (
    <div
      className={classnames(
        classes.status,
        status && classes[`status__${status}`]
      )}
    >
      {dispatchStatus === constants.dispatchStatus.PENDING ? (
        <PendingVehicleIcon />
      ) : (
        <DispatchVehicleIcon />
      )}
      <span>{status}</span>
    </div>
  );
};

export const MobileSecondaryAction = ({ record = {}, onChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.mobile_secondary_action}>
      <SwitchField
        record={record}
        source="dispatch_status"
        onChange={onChange}
      />
      <DispatchStatus record={record} source="dispatch_status" />
    </div>
  );
};

export const TableStatusField = ({ record = {} }) => {
  const statusObj =
    BOOKINGS_STATUS_CODES[BOOKINGS_STATUS_CODES_ENUM[record.status]];
  const status = statusObj?.name;
  const classes = useStyles();
  if (!status) return "-";

  return (
    <Chip
      chipClassName={classnames(
        classes.status_chip,
        classes[`status__${statusObj.className}`]
      )}
      labelClassName={classes.status_chip_label}
      label={status}
    />
  );
};
