import React from "react";
import { NEW_SECTION_OPTION_VALUE } from "../../../constants/ManageRecordings.constant";
import { is_empty } from "utils/validations";

const useCreateNewSection = (props) => {
  const {
    selectedValue,
    courseSectionOptions,
    setCourseSectionOptions,
    handleSectionChange,
    record,
  } = props;

  const { section: selectedSection } = selectedValue;

  const [showCreateNewSectionModal, setShowCreateNewSectionModal] =
    React.useState(false);
  const [newSectionText, setNewSectionText] = React.useState("");
  const [validationError, setValidationError] = React.useState("");

  const toggleCreateNewSectionModal = () => {
    setValidationError("");
    setShowCreateNewSectionModal((prev) => !prev);
  };

  /**
   * Callback for handleChange in dropdown. For new section toggle
   * the modal to create new section to open
   * @param { string } value Selected option value from the menu
   */
  const handleCourseSectionChange = (value) => {
    if (value === NEW_SECTION_OPTION_VALUE) {
      toggleCreateNewSectionModal();
      return;
    }
    const selectedOptionValue = courseSectionOptions.find(
      (option) => option.value === value
    );
    handleSectionChange(record, {
      record,
      section: {
        ...selectedOptionValue,
        isNewSection: Boolean(selectedOptionValue?.isNewSection),
      },
    });
  };

  const handlePrimaryCreateNewSectionModalBtnclick = () => {
    // Preventing the creator from creating a section with a name that
    // already exist in the rc only in this flow, as it leads to confusion to whether the creator wanted
    // 2 new sections with the same name or not. If they still want to keep the same name, they can do so in
    // Edit Offering of the RC.
    const isSectionNameAlreadyPresent = courseSectionOptions?.find(
      (section) => section?.label === newSectionText
    );
    if (is_empty(isSectionNameAlreadyPresent)) {
      setValidationError("");
      const newSectionData = {
        id: courseSectionOptions.length,
        label: newSectionText,
        value: `${newSectionText}-${courseSectionOptions.length}`,
        isNewSection: true,
      };
      setCourseSectionOptions((prev) => {
        const newPrev = [...prev];
        newPrev.splice(prev.length - 1, 0, newSectionData);
        return newPrev;
      });
      handleSectionChange(record, {
        record,
        section: newSectionData,
      });
      setNewSectionText("");
      toggleCreateNewSectionModal();
    } else {
      setValidationError("Section with this name already exists");
    }
  };

  return {
    selectedSection,
    showCreateNewSectionModal,
    newSectionText,
    setNewSectionText,
    validationError,
    setValidationError,
    handleCourseSectionChange,
    toggleCreateNewSectionModal,
    handlePrimaryCreateNewSectionModalBtnclick,
  };
};

export default useCreateNewSection;
