import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    qna_input_wrapper: {
      marginBottom: "16px",
    },
    qna_file_label: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
      color: theme?.palette?.secondary?.main,
      marginBottom: "8px",
    },
    bookingQuestionsWrapper: {
      overflow: ({ isDesktop }) => (isDesktop ? "" : "scroll"),
      padding: ({ isDesktop }) =>
        isDesktop ? "20.17px 24px 20px 24px" : "16.5px 16px 0px 16px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
