import React from "react";

import styles from "./CustomRecordSectionCard.module.css";

const CustomRecordSectionCard = (props) => {
  const {
    recordingTitle,
    sectionTitle,
    batchTitle,
    sessionTitle,
    customerTitle,
  } = props;

  return (
    <div className={styles.container_style}>
      <div className={styles.recording_container_style}>
        <img
          src="/assets/images/manage_recordings/recording_icon.svg"
          alt="recording"
        />
        <div className={styles.recording_text_style}>{recordingTitle}</div>
      </div>
      <div className={styles.info_container_style}>
        <div className={styles.section_container_style}>
          <div className={styles.section_heading_style}>Section:</div>
          <div className={styles.section_text_style}>{sectionTitle}</div>
        </div>
        {batchTitle && (
          <div className={styles.section_container_style}>
            <div className={styles.section_heading_style}>Batch:</div>
            <div className={styles.section_text_style}>{batchTitle}</div>
          </div>
        )}
        {sessionTitle && (
          <div className={styles.section_container_style}>
            <div className={styles.section_heading_style}>Session:</div>
            <div className={styles.section_text_style}>{sessionTitle}</div>
          </div>
        )}
        {customerTitle && (
          <div className={styles.section_container_style}>
            <div className={styles.section_heading_style}>Customer:</div>
            <div className={styles.section_text_style}>{customerTitle}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomRecordSectionCard;
