import React from "react";
import { useRefresh } from "react-admin";

import { getUpdatedRecordingToSections } from "../../../utils/ManageRecordings.util";
import { column_keys } from "../../../constants/ManageRecordings.constant";
import { dataProvider } from "data";
import { manage_recordings_api } from "../../../api/ManageRecordings.api";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import {
  notification_color_keys,
  useNotifications,
} from "utils/hooks/useNotifications";
import { logError } from "utils/error";
import styles from "../SaveSectionChangesModal.module.css";

const useSaveSectionChangesModal = (props) => {
  const {
    listingUuid,
    handleClose,
    sectionsData,
    batchesData,
    sessionsData,
    customersData,
    handleClearMappingStates,
  } = props;

  const [isSaveChangesLoading, setIsSaveChangesLoading] = React.useState(false);

  const refresh = useRefresh();
  const { notify } = useNotifications();

  // Here we remove the keys where the user has selected "-"
  // as an option from the dropdown, as we are treating "-"
  // option as no item selected
  const updatedRecordingToSections =
    getUpdatedRecordingToSections(sectionsData);

  /**
   * This is the function being called when on click
   * of save button in modal, here "data" is the payload of
   * save api. It consists of the zoom rc listing uuid, and an
   * array of secion mappings, each object of array consists of
   * recording_uid, mapped section uid or new section created title,
   * for case of classes it must contain batch uid, and for case of one
   * on one appointment it must also have the mapped customer uid
   */
  const handleSaveChangesClick = async () => {
    try {
      setIsSaveChangesLoading(true);
      const data = {
        listing_uid: listingUuid,
        mappings: Object.keys(updatedRecordingToSections).map((key) => ({
          recording_uid:
            sectionsData[key].record[column_keys.recordingId.apiKey],
          ...(sectionsData[key].section.isNewSection
            ? {
                new_section: {
                  id: sectionsData[key].section.value,
                  title: sectionsData[key].section.label,
                },
              }
            : {
                section_uid: sectionsData[key].section.value,
              }),
          ...(batchesData?.[key]
            ? {
                batch_uid: batchesData[key].batch.value,
              }
            : {}),
          ...(sessionsData?.[key]
            ? {
                session_uid: sessionsData[key].session.value,
              }
            : {}),
          ...(customersData?.[key]
            ? {
                customer_username: customersData[key].customer.value,
              }
            : {}),
        })),
      };
      const sectionMappingResponse = await dataProvider.custom_request(
        `${manage_recordings_api.save_recordings_sections}`,
        apiMethods.POST,
        data
      );
      if (isRequestSuccessful(sectionMappingResponse?.status)) {
        notify(
          <div>
            <div className={styles.toast_heading_style}>
              Recordings mapped successfully!
            </div>
            <div>
              Customer(s) will be able to access the recording in some time.
            </div>
          </div>,
          notification_color_keys.success
        );
        handleClearMappingStates();
        refresh();
        handleClose();
      }
    } catch (error) {
      notify("Something went wrong!", notification_color_keys.error);
      logError({
        error,
        occuredAt:
          "handleSaveChangesClick in src/ui/pages/schedule/ManageOffering/ManageRecordings/components/SaveSectionChangesModal/SaveSectionChangesModal.js",
        when: "user clicks on save changes in confirmation modal",
      });
    } finally {
      setIsSaveChangesLoading(false);
    }
  };

  return {
    updatedRecordingToSections,
    handleSaveChangesClick,
    isSaveChangesLoading,
  };
};

export default useSaveSectionChangesModal;
