import React from "react";
import {
  OfferingNameField,
  ListingTypeFieldFormatted,
  MeetingAddress,
} from "./components/TableFields/TableFields";
// Components
import { UTMInfoField } from "common/form/UTMInfoField";
import constants from "constants/constants";
import { upsellTypeOptions } from "constants/upsellConstants";
import { upsellTypeMap } from "ui/pages/customers/ManageBookings/constants";
import {
  ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS,
  PAYMENT_PLAN_TYPE_OPTIONS,
} from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import { is_empty } from "utils/validations";
import {
  DateTimeField,
  WhatsAppPhoneField,
} from "../../../../common/Components/TableFields/TableFields";
import constant from "../../../../constants/constants";
import MoneyField from "../../../modules/MoneyField";
import { InfoField } from "./components/TableFields/CustomColumns";
import formConstants from "./bookedSession.constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import classNames from "./BookedSessionStyles.module.css";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";
import {
  PAYMENT_DEBIT_MODES_LABELS,
  PAYMENT_DEBIT_MODE_OPTIONS,
} from "features/Listings/modules/PricingSection/PricingSection.constants";
import {
  ORDER_PAYMENT_TYPE,
  ORDER_PAYMENT_TYPE_LABELS,
} from "schedule-v2/constants";
import EmailField from "ui/Fields/EmailField";
import { listing_customer_country_code } from "features/Listings/Listings.constants";

const scheduleTypes = [];
constant.schedule_type.forEach((type) => {
  scheduleTypes.push({
    label: type.name,
    source: formConstants.form_data.offering_type.source,
    filterValue: type.id,
    value: type.id,
  });
});
export const tabFilterConfig = scheduleTypes;
const listingTypeOptions = constants.schedule_type.map((item) => ({
  ...item,
  label: item.name,
  value: item.id,
}));

export const getTableConfig = ({
  handleCustomersClick,
  showInfoModal,
  setSelectedRecord,
  isTransactionHidden,
  isCustomerDetailsHidden,
  from_bonus_offering,
  customColumnsToRender,
  handleBookingNavigationToCustomer,
  isOfferingAutoDebitEnabled,
}) => {
  return [
    {
      field: formConstants.form_data.name.field,
      headerName: formConstants.form_data.name.placeholder,
      avatar: true,
      isPrimary: true,
      isDrawerTitle: true,
      emptyField: "-",
      sortable: false,
      fixed: "left",
      width: "200px",
      valueFormatter: (record) => (
        <NameField
          record={record}
          source={formConstants.form_data.name.field}
          onClick={handleBookingNavigationToCustomer}
        />
      ),
    },
    {
      field: formConstants.form_data.email.field,
      headerName: formConstants.form_data.email.placeholder,
      emptyField: "-",
      sortable: false,
      columnClassName: "word_break",
      width: "200px",
      hidden: isCustomerDetailsHidden,
      hiddenDesktopColumn: isCustomerDetailsHidden,
      valueFormatter: (record) =>
        record[formConstants.form_data.email.field] ? (
          <EmailField
            record={record}
            source={formConstants.form_data.email.field}
          />
        ) : (
          "-"
        ),
    },
    {
      field: formConstants.form_data.phone.field,
      headerName: formConstants.form_data.phone.placeholder,
      valueFormatter: (record) => (
        <WhatsAppPhoneField
          record={record}
          source={formConstants.form_data.phone.field}
          countryCodeKey={listing_customer_country_code}
        />
      ),
      sortable: false,
      hidden: isCustomerDetailsHidden,
      hiddenDesktopColumn: isCustomerDetailsHidden,
    },
    {
      field: formConstants.form_data.offering.field,
      headerName: formConstants.form_data.offering.placeholder,
      sortable: false,
      valueFormatter: from_bonus_offering
        ? null
        : (record) => (
            <OfferingNameField
              record={record}
              source={formConstants.form_data.offering.field}
              onClick={handleCustomersClick}
            />
          ),
      emptyField: "-",
      noWrap: true,
      ellipsis: true,
      maxCharCount: 30,
    },
    {
      field: formConstants.form_data.price.field,
      headerName: formConstants.form_data.price.placeholder,
      valueFormatter: (record) => (
        <MoneyField
          record={record}
          source={formConstants.form_data.price.field}
          showGstHelper={true}
          creator_sales={false}
          noRightMargin={true}
        />
      ),
      sortable: false,
      hidden: isTransactionHidden,
      hiddenDesktopColumn: isTransactionHidden,
    },
    {
      field: formConstants.form_data.date.field,
      headerName: formConstants.form_data.date.placeholder,
      valueFormatter: (record) => (
        <DateTimeField
          record={record}
          source={formConstants.form_data.date.field}
        />
      ),
      sortable: false,
      noWrap: true,
    },
    {
      field: formConstants.form_data.payment_type.field,
      headerName: formConstants.form_data.payment_type.placeholder,
      valueFormatter: (record) => {
        const { payment_type } = record;
        return payment_type ? ORDER_PAYMENT_TYPE_LABELS[payment_type] : "-";
      },
      noWrap: true,
    },
    ...(isOfferingAutoDebitEnabled
      ? [
          {
            field: formConstants.form_data.payment_debit_mode.field,
            headerName: formConstants.form_data.payment_debit_mode.placeholder,
            valueFormatter: (record) => {
              const { payment_type } = record;
              return payment_type === ORDER_PAYMENT_TYPE.SUBSCRIPTION
                ? PAYMENT_DEBIT_MODES_LABELS[record?.payment_debit_mode]
                : "-";
            },
            noWrap: true,
          },
        ]
      : []),
    {
      field: formConstants.form_data.offering_type.field,
      headerName: formConstants.form_data.offering_type.placeholder,
      valueFormatter: (record) => (
        <ListingTypeFieldFormatted
          record={record}
          source={formConstants.form_data.offering_type.field}
        />
      ),
      sortable: false,
      isDrawerTitle: true,
      width: "200px",
    },
    {
      field: formConstants.form_data.staff.field,
      headerName: (
        <>
          {formConstants.form_data.staff.placeholder}{" "}
          <Tooltip
            title="This has been assigned from member selected in the offering "
            color="primary"
            arrow
          >
            <InfoOutlinedIcon className={classNames.infoIconStyle} />
          </Tooltip>
        </>
      ),
      valueFormatter: (record) =>
        is_empty(record.staff_name) ? "-" : record.staff_name,
      sortable: false,
      width: "200px",
    },
    {
      field: formConstants.form_data.listing_address.field,
      headerName: formConstants.form_data.listing_address.placeholder,
      valueFormatter: (record) => <MeetingAddress record={record} />,
      sortable: false,
      width: "200px",
    },
    {
      field: formConstants.form_data.upsell_type.field,
      headerName: formConstants.form_data.upsell_type.placeholder,
      valueFormatter: (record) => {
        if (upsellTypeMap[record.upsell_type])
          return upsellTypeMap[record.upsell_type];
        else return "-";
      },
      sortable: false,
      isDrawerTitle: true,
      width: "200px",
    },
    {
      field: formConstants.form_data.source_upsell_offering_name.field,
      headerName:
        formConstants.form_data.source_upsell_offering_name.placeholder,
      valueFormatter: (record) => {
        return (
          record[formConstants.form_data.source_upsell_offering_name.field] ||
          "-"
        );
      },
      sortable: false,
      isDrawerTitle: true,
      width: "200px",
    },
    {
      field: formConstants.form_data.utm_info.field,
      headerName: formConstants.form_data.utm_info.placeholder,
      valueFormatter: (record) =>
        !is_empty(record.utm_info) ? <UTMInfoField record={record} /> : "-",
      width: "400px",
      hidden: false,
      hiddenDesktopColumn: false,
    },
    /** append custom columns to be shown in table */
    ...customColumnsToRender,
    {
      field: formConstants.form_data.name.field,
      headerName: formConstants.form_data.action,
      valueFormatter: (record) => (
        <InfoField
          onClick={(record) => {
            showInfoModal();
            setSelectedRecord(record);
          }}
          record={record}
        />
      ),
      hidden: true,
      noWrap: true,
      fixed: "right",
    },
  ];
};

export const filterConfig = ({
  staffMembers,
  showStaffFilter,
  from_bonus_offering,
  customFiltersToAppend,
  isOfferingAutoDebitEnabled,
}) => {
  const return_array = [
    {
      source: formConstants.form_data.name.source,
      placeholder: formConstants.form_data.name.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: formConstants.form_data.email.source,
      placeholder: formConstants.form_data.email.placeholder,
      type: "input",
    },
    {
      source: formConstants.form_data.phone.source,
      placeholder: formConstants.form_data.phone.placeholder,
      type: "input",
    },
    {
      source: formConstants.form_data.offering.source,
      placeholder: formConstants.form_data.offering.placeholder,
      type: "input",
    },
    {
      source: formConstants.form_data.min_price.source,
      placeholder: formConstants.form_data.min_price.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: formConstants.form_data.max_price.source,
      placeholder: formConstants.form_data.max_price.placeholder,
      type: "input",
      disableContains: true,
    },
    {
      source: formConstants.form_data.date.source,
      placeholder: formConstants.form_data.date.filter_placeholder,
      type: "date",
    },
    {
      source: formConstants.form_data.payment_type.source,
      placeholder: formConstants.form_data.payment_type.placeholder,
      options: PAYMENT_PLAN_TYPE_OPTIONS,
      type: "dropdown",
      disableContains: true,
    },
    ...(isOfferingAutoDebitEnabled
      ? [
          {
            source: formConstants.form_data.payment_debit_mode.source,
            placeholder: formConstants.form_data.payment_debit_mode.placeholder,
            options: PAYMENT_DEBIT_MODE_OPTIONS,
            type: "dropdown",
            disableContains: true,
          },
        ]
      : []),
    {
      source: formConstants.form_data.offering_type.source,
      placeholder: formConstants.form_data.offering_type.placeholder,
      options: listingTypeOptions,
      type: "dropdown",
      disableContains: true,
    },
    {
      source: formConstants.form_data.staff.source,
      placeholder: formConstants.form_data.staff.placeholder,
      type: "dropdown",
      options: staffMembers,
      disableContains: true,
      hidden: !showStaffFilter,
    },
    ...(!from_bonus_offering
      ? [
          {
            source: formConstants.form_data.upsell_type.source,
            placeholder: formConstants.form_data.upsell_type.placeholder,
            options: upsellTypeOptions,
            type: "dropdown",
            disableContains: true,
          },
          {
            source: formConstants.form_data.source_upsell_offering_name.source,
            placeholder:
              formConstants.form_data.source_upsell_offering_name.placeholder,
            type: "input",
            disableContains: true,
          },
        ]
      : []),
    {
      source: formConstants.form_data.utm_source.source,
      placeholder: formConstants.form_data.utm_source.placeholder,
      type: "input",
    },
    {
      source: formConstants.form_data.utm_medium.source,
      placeholder: formConstants.form_data.utm_medium.placeholder,
      type: "input",
    },
    {
      source: formConstants.form_data.utm_campaign.source,
      placeholder: formConstants.form_data.utm_campaign.placeholder,
      type: "input",
    },
    /** append custom columns to be shown in filter options */
    ...customFiltersToAppend,
  ];

  return return_array;
};

export const tableChipsFormatter =
  (staffMembers, customChipsToFormat) => (filter, value) => {
    const columnToFilter = customChipsToFormat?.find(
      (chipToFormat) => chipToFormat.columnSlug === filter
    );
    if (columnToFilter) {
      const staff = staffMembers?.find((item) => item.id == value);
      return `${columnToFilter?.columnName}: ${staff?.label}`;
    } else {
      switch (filter) {
        case formConstants.form_data.name.source:
          return `${formConstants.form_data.name.placeholder}: ${value}`;
        case `${formConstants.form_data.phone.source}__icontains`:
          return `${formConstants.form_data.phone.placeholder}: ${value}`;
        case `${formConstants.form_data.email.source}__icontains`:
          return `${formConstants.form_data.email.placeholder}: ${value}`;
        case formConstants.form_data.offering_type.source: {
          const listing = listingTypeOptions?.find((item) => item.id == value);
          return `${formConstants.form_data.offering_type.placeholder}: ${listing?.label}`;
        }
        case formConstants.form_data.payment_type.field: {
          return `${formConstants.form_data.payment_type.placeholder}: ${ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS[value]}`;
        }
        case formConstants.form_data.payment_debit_mode.field: {
          return `${formConstants.form_data.payment_debit_mode.placeholder}: ${PAYMENT_DEBIT_MODES_LABELS[value]}`;
        }
        case formConstants.form_data.upsell_type.source: {
          return `${formConstants.form_data.upsell_type.placeholder}: ${upsellTypeMap[value]}`;
        }
        case formConstants.form_data.source_upsell_offering_name.source: {
          return `${formConstants.form_data.source_upsell_offering_name.placeholder}: ${value}`;
        }
        case `${formConstants.form_data.offering.source}__icontains`:
          return `${formConstants.form_data.offering.placeholder}: ${value}`;
        case formConstants.form_data.date.source:
          return `${formConstants.form_data.date.filter_placeholder}: ${value}`;
        case `${formConstants.form_data.utm_source.source}__icontains`:
          return `${formConstants.form_data.utm_source.placeholder}: ${value}`;
        case `${formConstants.form_data.utm_medium.source}__icontains`:
          return `${formConstants.form_data.utm_medium.placeholder}: ${value}`;
        case `${formConstants.form_data.utm_campaign.source}__icontains`:
          return `${formConstants.form_data.utm_campaign.placeholder}: ${value}`;
        case formConstants.form_data.staff.source: {
          const staff = staffMembers?.find((item) => item.id == value);
          return `${formConstants.form_data.staff.placeholder}: ${staff?.label}`;
        }
        case formConstants.form_data.min_price.source:
          return `${formConstants.form_data.min_price.placeholder}: ${value}`;
        case formConstants.form_data.max_price.source:
          return `${formConstants.form_data.max_price.placeholder}: ${value}`;
        default:
          return undefined;
      }
    }
  };
