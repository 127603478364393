import { makeStyles } from "@material-ui/core/styles";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import React from "react";
import { ReactComponent as TimedWarningIcon } from "../../../../../assets/vectors/timed_warning.svg";

const useStyles = makeStyles((theme) => ({
  modalPaperClassName: {
    minWidth: "532px",
  },
  desktopModalHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10.47px",
    height: "22px",
  },
  warningIcon: {
    color: theme?.palette?.butterscotch_yellow?.main,
  },
  desktopWarningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    padding: "32.5px 26px 32px 26px",
  },
  warningDescription: {
    color: theme?.palette?.secondary?.main,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
    width: "100%",
  },
  mobileModalHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10.49px",
    height: "22px",
  },
  warningWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    padding: "24.5px 16px 32px 16px",
  },
}));

const ExpireModal = ({
  open,
  onClose,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  isExpiringLink,
}) => {
  const isDesktop = window.screen.width > constants.mobile_width;
  const classes = useStyles();

  const primaryBtnProps = { loading: isExpiringLink, disabled: isExpiringLink };

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.modalPaperClassName}
      open={open}
      onClose={onClose}
      showCross
      primaryBtnText="Confirm"
      onPrimaryBtnClick={onPrimaryBtnClick}
      secondaryBtnText="Cancel"
      onSecondaryBtnClick={onSecondaryBtnClick}
      primaryBtnProps={primaryBtnProps}
      title={
        <div className={classes.desktopModalHeader}>
          <WarningRoundedIcon className={classes.warningIcon} />
          <span>Confirm Expire</span>
        </div>
      }
    >
      <div className={classes.desktopWarningWrapper}>
        <TimedWarningIcon />
        <div className={classes.warningDescription}>
          Are you sure you want to expire the link?
        </div>
      </div>
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      primaryAction={{
        label: "Confirm",
        onClick: onPrimaryBtnClick,
        btnProps: primaryBtnProps,
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: onSecondaryBtnClick,
      }}
      header={
        <div className={classes.mobileModalHeader}>
          <WarningRoundedIcon className={classes.warningIcon} />
          <span>Confirm Expire</span>
        </div>
      }
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.warningWrapper}>
        <TimedWarningIcon />
        <div className={classes.warningDescription}>
          Are you sure you want to expire the link?
        </div>
      </div>
    </MobileModal>
  );
};

export default ExpireModal;
