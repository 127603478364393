import React from "react";
import classnames from "classnames";
import Menu from "@material-ui/core/Menu";
import { InteractiveTooltipWrapper } from "schedule-v2/components";
import {
  MANAGE_RECORDING_TOOLTIP_DATA,
  MANAGE_RECORDING_TOOLTIP_WEIGHTS,
} from "../../../../constants/ManageRecordings.constant";
import styles from "./CourseSectionTooltip.module.css";

const CourseSectionTooltip = (props) => {
  const { courseSectionOptions, currentActive } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuAnchorRef = React.useRef(null);

  React.useEffect(() => {
    // Set the anchorEl to the div when the component mounts
    if (menuAnchorRef.current) {
      setAnchorEl(menuAnchorRef.current);
    }
  }, [menuAnchorRef, currentActive]);

  const tooltipMenuStyle = classnames(
    styles.tooltip_menu_style,
    currentActive !== MANAGE_RECORDING_TOOLTIP_WEIGHTS.create_section &&
      styles.hide_tooltip_menu_style
  );

  const tooltipMenuOptions = courseSectionOptions?.map((option) => {
    return (
      <div className={styles.tooltip_menu_item_style} key={option.id}>
        {option.label}
      </div>
    );
  });

  return (
    <div>
      <div ref={menuAnchorRef} />
      <div
        className={tooltipMenuStyle}
        aria-controls="simple-menu"
        aria-haspopup="true"
      >
        <Menu
          open
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={
            currentActive !== MANAGE_RECORDING_TOOLTIP_WEIGHTS.create_section &&
            styles.hide_menu
          }
          classes={{
            paper: styles.menu_style,
          }}
          disableScrollLock
        >
          {tooltipMenuOptions}
          <InteractiveTooltipWrapper.Tooltip
            weight={MANAGE_RECORDING_TOOLTIP_WEIGHTS.create_section}
            bottom={true}
            content={MANAGE_RECORDING_TOOLTIP_DATA.create_section}
            className={styles.tooltip}
          />
        </Menu>
      </div>
    </div>
  );
};

export default CourseSectionTooltip;
