import React from "react";
import { InteractiveTooltipWrapper } from "schedule-v2/components";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ManageRecordingsTable from "./ManageRecordingsTable";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import useManageRecordings from "./hooks/useManageRecordings";
import { manage_recordings_onboarding_keys } from "./constants/ManageRecordings.constant";

const ManageRecordings = (props) => {
  const {
    listingUuid,
    parentOffering = {},
    showViewRecordingModal,
    viewRecordingModalData,
    handleToggleShowViewRecordingModal,
    handleViewRecordingActionClick,
    recordToSelectedSection,
    handleSelectedSectionMappingChange,
    courseSectionOptions,
    setCourseSectionOptions,
    showSectionChangesModal,
    toggleShowSaveSectionChangesModal,
    handleSaveChnagesClick,
    batchesProps,
    sessionsProps,
    customersProps,
    isPageDataLoaing,
    getZoomRcOfferingDetails,
    handleClearMappingStates,
  } = useManageRecordings();

  const { isOnboarded, flagLoading, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker({
      featureKey: manage_recordings_onboarding_keys[parentOffering.type],
    });

  const handleTooltipClose = () => {
    handleFeatureOnboardChange(true);
  };

  return (
    <div id="mainLayout">
      <InteractiveTooltipWrapper onClose={handleTooltipClose}>
        <ManageRecordingsTable
          tableProps={props}
          isOnboarded={isOnboarded}
          flagLoading={flagLoading}
          recordingProps={{
            listingUuid,
            parentOffering,
            showViewRecordingModal,
            viewRecordingModalData,
            handleToggleShowViewRecordingModal,
            handleViewRecordingActionClick,
            recordToSelectedSection,
            handleSelectedSectionMappingChange,
            courseSectionOptions,
            setCourseSectionOptions,
            showSectionChangesModal,
            toggleShowSaveSectionChangesModal,
            handleSaveChnagesClick,
            batchesProps,
            sessionsProps,
            customersProps,
            isPageDataLoaing,
            getZoomRcOfferingDetails,
            handleClearMappingStates,
          }}
        />
      </InteractiveTooltipWrapper>
    </div>
  );
};

export default withComponentLibraryTheme(ManageRecordings);
