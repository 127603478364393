import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";

export const getCustomColumns = (section_name) => {
  return dataProvider.custom_request(
    api.custom_columns.get_custom_columns,
    apiMethods.GET,
    { section_name }
  );
};

export const assignCustomMember = ({
  value,
  targetObject,
  customColumnUuid,
  columnSlug,
}) => {
  return dataProvider.custom_request(
    api.custom_columns.post_custom_column_data,
    apiMethods.POST,
    {
      custom_column_uuid: customColumnUuid,
      [columnSlug]: value,
      target_object: targetObject,
    }
  );
};

export const getCustomerByGuestName = (payload) => {
  return dataProvider.custom_request(
    api.crm.customer_filter,
    apiMethods.GET,
    payload
  );
};
