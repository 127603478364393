import React from "react";
import classnames from "classnames";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Dropdown } from "@my-scoot/component-library-legacy";

import CreateNewSectionModal from "../CreateNewSectionModal/CreateNewSectionModal";
import { FIRST_ITEM_INDEX } from "../../constants/ManageRecordings.constant";
import CourseSectionTooltip from "./component/CourseSectionTooltip/CourseSectionTooltip";
import useCreateNewSection from "./util/useCreateNewSection";
import styles from "./CourseSectionColumn.module.css";

const CourseSectionColumn = (props) => {
  const {
    record,
    selectedValue = {},
    handleSectionChange,
    width = "150px",
    courseSectionOptions = [],
    setCourseSectionOptions,
    currentActive,
  } = props;

  const {
    selectedSection,
    showCreateNewSectionModal,
    newSectionText,
    setNewSectionText,
    validationError,
    setValidationError,
    handleCourseSectionChange,
    toggleCreateNewSectionModal,
    handlePrimaryCreateNewSectionModalBtnclick,
  } = useCreateNewSection({
    selectedValue,
    courseSectionOptions,
    setCourseSectionOptions,
    handleSectionChange,
    record,
  });

  return (
    <div className={styles.main_class_style}>
      {showCreateNewSectionModal ? (
        <div className={styles.dropdown_text_style}>
          <div
            className={classnames(
              styles.dropdown_text_label_style,
              !selectedSection?.label && styles.placeholder_style
            )}
          >
            {selectedSection?.label || "Select Section"}
          </div>
          <ExpandMoreRoundedIcon className={styles.icon_style} />
        </div>
      ) : (
        <div className={styles.select_container_style}>
          <Dropdown
            value={selectedSection?.value}
            options={courseSectionOptions}
            width={width}
            restMenuProps={{
              menu_paper_classname: styles.menu_container_style,
            }}
            placeholder="Select Section"
            handleChange={handleCourseSectionChange}
          />
          {/**
           * For onboarding flow, we render a static menu
           * of dropdown with the interactive tooltip, as
           * user does not need to have any interaction with
           * the menu.
           */}
          {record?.id === FIRST_ITEM_INDEX && (
            <CourseSectionTooltip
              courseSectionOptions={courseSectionOptions}
              currentActive={currentActive}
            />
          )}
        </div>
      )}
      {showCreateNewSectionModal && (
        <CreateNewSectionModal
          open={showCreateNewSectionModal}
          handleClose={toggleCreateNewSectionModal}
          newSectionText={newSectionText}
          setNewSectionText={setNewSectionText}
          validationError={validationError}
          setValidationError={setValidationError}
          onPrimaryBtnClick={handlePrimaryCreateNewSectionModalBtnclick}
        />
      )}
    </div>
  );
};

export default CourseSectionColumn;
